import { createContext, useContext, useMemo } from 'react';

import { BridgePageData } from '@/types/cms/bridge-page.type';
import { ChainPageHomeDatum } from '@/types/cms/chain-page.types';
import { DefiPageData } from '@/types/cms/defi-page.types';
import { LandingPageNavData } from '@/types/cms/landing-page-nav.type';
import { StakingPageGeneralData } from '@/types/cms/staking-page-general.type';
import { StakingPageData } from '@/types/cms/staking-page.types';
import { VaultPageData } from '@/types/cms/vault-page.type';

interface CMSProviderData {
  // * Stake, Embed & Utility Pool
  page: StakingPageData;
  stakeGeneral: StakingPageGeneralData | null;

  // * Home & Node Operator
  landing: ChainPageHomeDatum | null;
  landingNav: LandingPageNavData | null;

  // * Defi
  defi: DefiPageData | null;

  // * Bridge
  bridge: BridgePageData | null;

  // * Vault
  vault: VaultPageData | null;
}

type CMSDataProviderProps = Partial<CMSProviderData> & {
  page: StakingPageData;
  children: React.ReactNode;
};

const CMSDataContext = createContext<CMSProviderData | null>(null);

const CMSDataProvider: React.FC<CMSDataProviderProps> = ({
  page,
  stakeGeneral,
  landing,
  landingNav,
  defi,
  bridge,
  vault,
  children,
}) => {
  const data = useMemo<CMSProviderData>(
    () => ({
      page: page,
      stakeGeneral: stakeGeneral ?? null,
      landing: landing ?? null,
      landingNav: landingNav ?? null,
      defi: defi ?? null,
      bridge: bridge ?? null,
      vault: vault ?? null,
    }),
    [],
  );

  return (
    <CMSDataContext.Provider value={data}>{children}</CMSDataContext.Provider>
  );
};

export { CMSDataProvider, CMSDataContext, type CMSProviderData };
