import { ReactNode } from 'react';

import Head from 'next/head';
import { usePathname } from 'next/navigation';

import { BNB, ETH, FANTOM, HEDERA, POLYGON, SD } from '@/constants/constants';
import { useCMSData } from '@/providers/CMSDataProvider';
import { StakingPageGeneralData } from '@/types/cms/staking-page-general.type';
import { getBaseUrl, getCurrentChain, getQueryString } from '@/utils/common';

import { BNBFAQSchema, ETHFAQSchema, POLYGONFAQSchema } from './JSONScript';

const noIndexFollow = 'noindex, follow';
const indexFollow = 'index, follow';

interface PageHeadProps {
  /**
   * stake, embed and utility_pool are basically the same page
   */
  page:
    | 'stake'
    | 'embed'
    | 'vault'
    | 'defi'
    | 'index'
    | 'node-operators'
    | 'utility_pool'
    | 'bridge'
    | 'other';
  children: ReactNode;
  TOKEN?: string;
}
const faqSchema = (TOKEN: string | undefined) => {
  switch (TOKEN) {
    case ETH:
      return ETHFAQSchema;
    case POLYGON:
      return POLYGONFAQSchema;
    case BNB:
      return BNBFAQSchema;
    default:
      return ETHFAQSchema;
  }
};

const defiMetaRobots = (TOKEN: string | undefined) => {
  switch (TOKEN) {
    case SD:
      return indexFollow;
    default:
      return noIndexFollow;
  }
};

const stakeMetaRobots = (TOKEN: string | undefined) => {
  switch (TOKEN) {
    case HEDERA:
      return indexFollow;
    case FANTOM:
      return indexFollow;
    default:
      return noIndexFollow;
  }
};

export const getURLParams = () => {
  const queryString =
    typeof window === 'undefined'
      ? ''
      : getQueryString(window?.location?.search);
  const origin = typeof window === 'undefined' ? '' : window?.location?.origin;
  return { queryString, origin };
};

const getPageData = (page: string, cmsData: StakingPageGeneralData | null) => {
  if (cmsData?.seo_header && cmsData.seo_header.length > 0) {
    let data: any = null;
    // TODO: add proper type
    cmsData.seo_header.forEach((item: { page?: string }) => {
      if (item.page === page) {
        data = item;
      }
    });
    return data;
  }
  return null;
};

const PageHeadCommon = ({ children, page, TOKEN }: PageHeadProps) => {
  const cmsData = useCMSData((data) => data.stakeGeneral);

  if (page === 'utility_pool' && TOKEN !== 'SD') {
    const { queryString, origin } = getURLParams();
    const metaContent = `0; url='${origin}${getBaseUrl()}stake/'}`;

    return (
      <Head>
        <meta
          httpEquiv="Refresh"
          content={`${metaContent}${queryString ? `?${queryString}` : ''}`}
        />
      </Head>
    );
  }
  if (page === 'bridge' && TOKEN !== 'ETH' && TOKEN !== 'SD') {
    const { queryString, origin } = getURLParams();
    const metaContent = `0; url='${origin}${getBaseUrl()}stake/'}`;
    return (
      <Head>
        <meta
          httpEquiv="Refresh"
          content={`${metaContent}${queryString ? `?${queryString}` : ''}`}
        />
      </Head>
    );
  }
  const data = getPageData(page, cmsData);

  if (data) {
    return (
      <>
        <Head>
          {page === 'index' && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(faqSchema(TOKEN)),
              }}
            />
          )}
          {data.title && <title>{data.title}</title>}
          {data.description && (
            <meta name="description" content={data.description} />
          )}
          {data.keywords && <meta name="keywords" content={data.keywords} />}
          {data.title && <meta property="og:title" content={data.title} />}
          {data.description && (
            <meta property="og:description" content={data.description} />
          )}
          {data.robots && <meta name="robots" content={data.robots} />}
          {data.canonical_url && (
            <link rel="canonical" href={data.canonical_url} />
          )}
        </Head>
        {data.h1 && <h1 style={{ height: 0, opacity: 0 }}>{data.h1}</h1>}
        {children}
      </>
    );
  }
  if (page === 'index') {
    return (
      <>
        <Head>
          {TOKEN === 'ETH' ? (
            <>
              <title>
                Ethereum Staking - Stake Ether & Earn Eth2 Rewards | Stader Labs
              </title>
              <meta
                name="description"
                content="Ethereum Staking - Stake Ether with Stader & earn rewards. Earn Eth2 rewards across the Ethereum ecosystem"
              />
            </>
          ) : (
            <title>{`${TOKEN} Liquid Staking`}</title>
          )}
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(faqSchema(TOKEN)),
            }}
          />
        </Head>
        <h1 style={{ height: 0, opacity: 0 }}>
          Ethereum Staking | ETH 2 Rewards
        </h1>
        {children}
      </>
    );
  }

  if (page === 'defi') {
    return (
      <>
        <Head>
          <title>DeFi | Stader</title>
          <meta name="robots" content={defiMetaRobots(TOKEN)} />
        </Head>
        {children}
      </>
    );
  }

  if (page === 'embed') {
    return (
      <>
        <Head>
          <meta name="robots" content="noindex, nofollow" />
        </Head>
        {children}
      </>
    );
  }

  return (
    <>
      <Head>
        <meta name="robots" content={stakeMetaRobots(TOKEN)} />
      </Head>
      {children}
    </>
  );
};

const PageHeadSD = () => {
  const { queryString, origin } = getURLParams();
  const metaContent = `0; url='${origin}${getBaseUrl()}utility_pool/'}`;

  return (
    <Head>
      <meta
        httpEquiv="Refresh"
        content={`${metaContent}${queryString ? `?${queryString}` : ''}`}
      />
    </Head>
  );
};

// const PageHeadHedera = () => {
//   const { queryString, origin } = getURLParams();
//   const metaContent = `0; url='${origin}${getBaseUrl()}stake/'}`;
//   return (
//     <Head>
//       <meta
//         httpEquiv="Refresh"
//         content={`${metaContent}${queryString ? `?${queryString}` : ''}`}
//       />
//     </Head>
//   );
// };

// const PageHeadFantom = () => {
//   const { queryString, origin } = getURLParams();
//   const metaContent = `0; url='${origin}${getBaseUrl()}stake/'}`;
//   return (
//     <Head>
//       <meta
//         httpEquiv="Refresh"
//         content={`${metaContent}${queryString ? `?${queryString}` : ''}`}
//       />
//     </Head>
//   );
// };

const PageHead = ({ page = 'other', children }: PageHeadProps) => {
  const pathname = usePathname();
  const TOKEN = getCurrentChain(pathname);

  switch (TOKEN) {
    case SD:
      return page === 'index' || page === 'stake' || page === 'embed' ? (
        <PageHeadSD />
      ) : (
        <PageHeadCommon page={page} TOKEN={TOKEN}>
          {children}
        </PageHeadCommon>
      );
    // case HEDERA:
    //   return page === 'index' ? (
    //     <PageHeadHedera />
    //   ) : (
    //     <PageHeadCommon page={page}>{children}</PageHeadCommon>
    //   );

    // case FANTOM:
    //   return page === 'stake' ? (
    //     <PageHeadCommon page={page}>{children}</PageHeadCommon>
    //   ) : (
    //     <PageHeadFantom />
    //   );

    default:
      return (
        <PageHeadCommon page={page} TOKEN={TOKEN}>
          {children}
        </PageHeadCommon>
      );
  }
};

export default PageHead;
