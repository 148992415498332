import { useContext } from 'react';

import { BridgePageData } from '@/types/cms/bridge-page.type';
import { ChainPageHomeDatum } from '@/types/cms/chain-page.types';
import { DefiPageData } from '@/types/cms/defi-page.types';
import { LandingPageNavData } from '@/types/cms/landing-page-nav.type';
import { StakingPageGeneralData } from '@/types/cms/staking-page-general.type';
import { StakingPageData } from '@/types/cms/staking-page.types';
import { VaultPageData } from '@/types/cms/vault-page.type';

import { CMSDataContext, CMSProviderData } from './CMSDataProvider';

/**
 * Use this hook only common components used across pages,
 * else prefer page specific hook for extra type safety
 */
function useCMSData<T = CMSProviderData>(
  selector?: (data: CMSProviderData) => T,
): T {
  const ctx = useContext(CMSDataContext);

  if (!ctx) {
    throw new Error('useCMSData should be called within CMSDataProvider');
  }

  return selector ? selector(ctx) : (ctx as T);
}

interface LandingPageCMSData {
  landing: ChainPageHomeDatum;
  landingNav: LandingPageNavData;
}

function useLandingPageCMSData(): LandingPageCMSData;
function useLandingPageCMSData<T>(selector: (data: LandingPageCMSData) => T): T;
function useLandingPageCMSData<T>(
  selector?: (data: LandingPageCMSData) => T,
): T | LandingPageCMSData {
  const ctx = useContext(CMSDataContext);
  if (!ctx?.landing || !ctx?.landingNav) {
    throw new Error('Landing page data not available');
  }

  const data = { landing: ctx.landing, landingNav: ctx.landingNav };
  return selector ? selector(data) : data;
}

interface StakePageCMSData {
  page: StakingPageData;
  stakeGeneral: StakingPageGeneralData;
}

function useStakePageCMSData(): StakePageCMSData;
function useStakePageCMSData<T>(selector: (data: StakePageCMSData) => T): T;
function useStakePageCMSData<T>(
  selector?: (data: StakePageCMSData) => T,
): T | StakePageCMSData {
  const ctx = useContext(CMSDataContext);
  if (!ctx?.page || !ctx?.stakeGeneral) {
    throw new Error('Stake page data not available');
  }

  const data = { page: ctx.page, stakeGeneral: ctx.stakeGeneral };
  return selector ? selector(data) : data;
}

interface DefiPageCMSData {
  page: StakingPageData;
  stakeGeneral: StakingPageGeneralData;
  defi: DefiPageData;
}

function useDefiPageCMSData(): DefiPageCMSData;
function useDefiPageCMSData<T>(selector: (data: DefiPageCMSData) => T): T;
function useDefiPageCMSData<T>(
  selector?: (data: DefiPageCMSData) => T,
): T | DefiPageCMSData {
  const ctx = useContext(CMSDataContext);
  if (!ctx?.page || !ctx?.defi || !ctx?.stakeGeneral) {
    throw new Error('Defi page data not available');
  }

  const data = {
    page: ctx.page,
    defi: ctx.defi,
    stakeGeneral: ctx.stakeGeneral,
  };
  return selector ? selector(data) : data;
}

interface BridgePageCMSData {
  page: StakingPageData;
  stakeGeneral: StakingPageGeneralData;
  bridge: BridgePageData;
}

function useBridgePageCMSData(): BridgePageCMSData;
function useBridgePageCMSData<T>(selector: (data: BridgePageCMSData) => T): T;
function useBridgePageCMSData<T>(
  selector?: (data: BridgePageCMSData) => T,
): T | BridgePageCMSData {
  const ctx = useContext(CMSDataContext);
  if (!ctx?.page || !ctx?.bridge || !ctx?.stakeGeneral) {
    throw new Error('Bridge page data not available');
  }

  const data = {
    page: ctx.page,
    bridge: ctx.bridge,
    stakeGeneral: ctx.stakeGeneral,
  };
  return selector ? selector(data) : data;
}

interface VaultPageCMSData {
  page: StakingPageData;
  stakeGeneral: StakingPageGeneralData;
  vault: VaultPageData;
}

function useVaultPageCMSData(): VaultPageCMSData;
function useVaultPageCMSData<T>(selector: (data: VaultPageCMSData) => T): T;
function useVaultPageCMSData<T>(
  selector?: (data: VaultPageCMSData) => T,
): T | VaultPageCMSData {
  const ctx = useContext(CMSDataContext);
  if (!ctx?.page || !ctx?.vault || !ctx?.stakeGeneral) {
    throw new Error('Vault page data not available');
  }

  const data = {
    page: ctx.page,
    vault: ctx.vault,
    stakeGeneral: ctx.stakeGeneral,
  };
  return selector ? selector(data) : data;
}

export {
  useCMSData,
  useLandingPageCMSData,
  useStakePageCMSData,
  useDefiPageCMSData,
  useBridgePageCMSData,
  useVaultPageCMSData,
};
